import React, { useEffect } from 'react';

import { getConfig } from 'config/get-config';
import { TeamsSkeletonLayout } from 'layouts/teams/TeamsSkeletonLayout';
import { redirect } from 'utils/redirect';

const { DEFAULT_ROUTE } = getConfig();

const Home = () => {
  useEffect(() => {
    redirect({
      target: DEFAULT_ROUTE,
      code: 307,
    });
  }, []);

  return <TeamsSkeletonLayout />
};

export default Home;
